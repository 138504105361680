import { useToast } from '@chakra-ui/react';
import type { PrivyEvents } from '@privy-io/react-auth';
import { getAccessToken, useLogin } from '@privy-io/react-auth';
import { signMessage } from '@wagmi/core';
import { useRouter } from 'next/router';
import { useConfig } from 'wagmi';

import { SignIn, WhoAmI } from '@endaoment-frontend/api';
import { isAdministrativeAccount, useSignOut } from '@endaoment-frontend/authentication';
import { isFetchError } from '@endaoment-frontend/data-fetching';
import { addressSchema } from '@endaoment-frontend/types';
import { makeValidLoginSignature } from '@endaoment-frontend/utils';

export const useAdminLogin = () => {
  const router = useRouter();
  const toast = useToast();
  const wagmiConfig = useConfig();
  const signOut = useSignOut();
  const handleLoginComplete: PrivyEvents['login']['onComplete'] = async (
    user,
    _isNewUser,
    _wasAlreadyAuthenticated,
  ) => {
    // Get token to send to the BE
    const privyToken = await getAccessToken();
    if (!privyToken) throw new Error('No privy token found');

    if (!user.wallet || !user.wallet.address || !isAdministrativeAccount(user.wallet.address)) {
      await signOut();
      throw new Error('Logged a user in without a wallet');
    }
    const walletAddress = addressSchema.parse(user.wallet.address);

    // Check if we can early exit if the user is already signed in
    try {
      const ndaoToken = await WhoAmI.execute();
      if (ndaoToken) return;
    } catch (e) {
      // If the user is not signed in (on a 401 error), we want to continue
      if (!isFetchError(e) || e.statusCode !== 401) throw e;
    }

    try {
      // Admins must sign to prove their identity
      const siweMessage = makeValidLoginSignature(walletAddress);
      const signature = await signMessage(wagmiConfig, {
        account: walletAddress,
        message: siweMessage.message,
      });

      await SignIn.execute({
        type: 'admin',
        privyToken,
        activeWalletAddress: walletAddress,
        signature,
        signatureDateUtc: siweMessage.date,
      });

      return;
    } catch (e) {
      toast({
        description: 'This is not an administrative account',
      });
      await signOut();
      router.push('/');
      throw e;
    }
  };

  const { login } = useLogin({
    onComplete: handleLoginComplete,
  });

  return {
    login,
  };
};
