import { useModalStatus, usePrivy } from '@privy-io/react-auth';

import { useAuth } from './useAuth';
import { useSignOut } from './useSignOut';

export const useWalletModal = () => {
  const { isSignedIn, isSignedInOnNdao, isSignedInOnPrivy, isLoading } = useAuth();
  const { ready, login } = usePrivy();
  const signOut = useSignOut();
  const { isOpen } = useModalStatus();
  const showWallet = async (shouldDisconnectIfSignedIn = false) => {
    if (isLoading || !ready) return;

    if (shouldDisconnectIfSignedIn && isSignedIn) {
      signOut();
      return;
    }

    console.info(`Opening wallet modal with user signed ${isSignedIn ? 'in' : 'out'}. `, {
      isSignedInOnPrivy,
      isSignedInOnNdao,
    });
    if (!isSignedIn && (isSignedInOnPrivy || isSignedInOnNdao)) {
      console.warn('Mismatch between Privy and NDAO. Signing out.');
      await signOut();
    }

    login();
  };

  return {
    showWallet,
    isWalletModalOpen: isOpen,
  };
};
