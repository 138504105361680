import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { ReactElement } from 'react';
import { PrivyProvider } from '@privy-io/react-auth';
import { WagmiProvider, createConfig } from '@privy-io/wagmi';

import { convertDehydratedStringToState, defaultQueryClient } from '@endaoment-frontend/data-fetching';
import { StylesProvider } from '@endaoment-frontend/ui/shared';
import { config } from '@endaoment-frontend/config';
import { WALLET_CONNECT_PROJECT_ID } from '@endaoment-frontend/constants';
import { createTransportsForChains, getConnectors } from '@endaoment-frontend/multichain';

import { AdminLoginWrapper } from './AdminLoginWrapper';

const connectors = getConnectors({
  appName: 'app.endaoment',
  appDescription: 'Endaoment',
  appUrl: 'https://app.endaoment.org',
  appLogo: 'https://app.endaoment.org/images/favicon.ico',
});
const transports = createTransportsForChains(config.chains);
const wagmiConfig = createConfig({
  chains: config.chains,
  connectors,
  transports,
  ssr: true,
});

export const AppProvider = ({
  dehydratedState,
  children,
}: {
  dehydratedState?: string;
  children: Array<ReactElement> | ReactElement;
}) => {
  return (
    <StylesProvider>
      <PrivyProvider
        appId={config.privyAppId}
        config={{
          // Customize Privy's appearance in your app
          appearance: {
            walletList: ['detected_wallets', 'metamask', 'coinbase_wallet', 'wallet_connect'],
            showWalletLoginFirst: true,
          },
          // Create embedded wallets for users who don't have a wallet
          embeddedWallets: {
            createOnLogin: 'users-without-wallets',
          },
          supportedChains: config.chains,
          walletConnectCloudProjectId: WALLET_CONNECT_PROJECT_ID,
          loginMethods: ['wallet'],
        }}>
        {/* QueryClientProvider MUST be above WagmiProvider */}
        <QueryClientProvider client={defaultQueryClient}>
          <WagmiProvider config={wagmiConfig}>
            {children}
            <HydrationBoundary state={convertDehydratedStringToState(dehydratedState)} />
            <AdminLoginWrapper>{children}</AdminLoginWrapper>
            <ReactQueryDevtools initialIsOpen={false} />
          </WagmiProvider>
        </QueryClientProvider>
      </PrivyProvider>
    </StylesProvider>
  );
};
