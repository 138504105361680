import { Center, Spinner } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Suspense } from 'react';

import { AppProvider } from '../components/AppProvider';
import { Navbar } from '../components/navbar';

// Required for proper stringification of BigInts, specially for cacheing and (de)hydration
// This is inserted here so both client and server pick up the polyfil
// @ts-expect-error Override
// eslint-disable-next-line no-extend-native
BigInt.prototype.toJSON = function () {
  return { value: this.toString(), _datatype: 'bigint' };
};

const App = ({ Component, pageProps, router }: AppProps<{ dehydratedState?: string }>) => {
  return (
    <>
      <Head>
        <link rel='icon' href='/images/favicon.ico' />
        <title>Endaoment Admin</title>

        {/* NEVER index admin pages */}
        <meta name='robots' content='noindex' />
      </Head>
      <AppProvider dehydratedState={pageProps.dehydratedState}>
        <Navbar />
        <main className='app'>
          <Suspense
            fallback={
              <Center>
                <Spinner />
              </Center>
            }
            key={router.asPath}>
            <Component {...pageProps} />
          </Suspense>
        </main>
      </AppProvider>
    </>
  );
};

export default App;
