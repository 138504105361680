import type { Chain } from 'viem';

export const RPC_URLS: Record<Chain['id'], Array<string>> = {
  // Mainnets
  1: [
    'https://mainnet.infura.io/v3/510c5afa18eb4eb9b611f010b805c393',
    'https://eth-mainnet.g.alchemy.com/v2/ao2D0tkhGEKpa2l-kyzmBhxyXm1Md9my',
    'https://mainnet.gateway.tenderly.co/4zmtwDeAz3MODjVA9Lj2Lz',
  ],
  10: [
    'https://optimism-mainnet.infura.io/v3/510c5afa18eb4eb9b611f010b805c393',
    'https://opt-mainnet.g.alchemy.com/v2/uqpk8cgLUX7oimlg-lQ-uOruCN6mkpr8',
    'https://optimism.gateway.tenderly.co/4zmtwDeAz3MODjVA9Lj2Lz',
  ],
  8453: [
    'https://base-mainnet.g.alchemy.com/v2/TgMmNhKZcm8fQMGiq_XJ7UIAtqfq74IO',
    'https://base.gateway.tenderly.co/4zmtwDeAz3MODjVA9Lj2Lz',
    'https://indulgent-wandering-mountain.base-mainnet.quiknode.pro/3383d83de8323da271bfbc01bd220e703e4729b8',
  ],
  // Testnets
  31337: ['http://127.0.0.1:8545'],
  31338: ['http://127.0.0.1:8546'],
  84532: [
    'https://base-sepolia.g.alchemy.com/v2/7rM_WmTTXQMLGe5CfSZkX7GHtOoe7yYF',
    'https://base-sepolia.gateway.tenderly.co/4zmtwDeAz3MODjVA9Lj2Lz',
  ],
  11155111: [
    'https://sepolia.infura.io/v3/510c5afa18eb4eb9b611f010b805c393',
    'https://eth-sepolia.g.alchemy.com/v2/PiTxkzC2yCaX4AoUXVZ4zKUPlYlOAoiN',
    'https://sepolia.gateway.tenderly.co/4zmtwDeAz3MODjVA9Lj2Lz',
  ],
  11155420: [
    'https://optimism-sepolia.infura.io/v3/510c5afa18eb4eb9b611f010b805c393',
    'https://opt-sepolia.g.alchemy.com/v2/_pXzgK5f94Tx8jbLHhBSOoEWxCxzS7Xb',
    'https://optimism-sepolia.gateway.tenderly.co/4zmtwDeAz3MODjVA9Lj2Lz',
  ],
};
