import {
  Button,
  Checkbox,
  Container,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';

export const OrgDeployerModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Container centerContent>
            <Heading size='md'>Enter EINs to deploy</Heading>
            <Textarea placeholder='EINs separated by comma' />
            <Checkbox> Send notification email</Checkbox>
            <Button>Deploy</Button>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
