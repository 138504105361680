import type { User } from '@privy-io/react-auth';
import { usePrivy } from '@privy-io/react-auth';
import { useQuery } from '@tanstack/react-query';

import { WhoAmI } from '@endaoment-frontend/api';
import type { Address } from '@endaoment-frontend/types';
import { addressSchema } from '@endaoment-frontend/types';
import { equalAddress } from '@endaoment-frontend/utils';

import { getSmartAccountAddressForEOA } from './getSmartAccountAddressForEOA';
import { isUserWalletSocial } from './useAuthType';

type UseAuthReturnType = {
  isSignedIn: boolean;
  authAddress?: Address;
  user?: User;
  isSignedInOnPrivy: boolean | undefined;
  isSignedInOnNdao: boolean | undefined;
  isLoading: boolean;
};

/**
 * Must be called within a child of the PrivyProvider.
 */
export const useAuth = (): UseAuthReturnType => {
  const { ready, authenticated, user } = usePrivy();
  const { data: currentPrivyWalletAddress, isPending: isPendingPrivyAddress } = useQuery({
    queryKey: ['GetPrivyWalletForUser', user?.wallet],
    queryFn: () => getPrivyWalletAddressForUser(user),
    refetchOnMount: false,
    retryOnMount: false,
    staleTime: Infinity,
  });
  const isPendingPrivy = !ready || isPendingPrivyAddress;
  const isSignedInOnPrivy = isPendingPrivy ? undefined : authenticated;

  const { data: ndaoAuthInfo, isPending: isPendingNdaoAuth } = WhoAmI.useQuery([], {
    refetchOnMount: false,
    retryOnMount: false,
  });
  const isSignedInOnNdao = isPendingNdaoAuth ? undefined : !!ndaoAuthInfo;
  const currentNdaoWalletAddress = !isPendingNdaoAuth && !!ndaoAuthInfo ? ndaoAuthInfo.wallet : undefined;

  // While we are waiting for the NDAO auth info, we will pretend that the user is consistently signed in
  if (isPendingNdaoAuth)
    return {
      isSignedIn: typeof isSignedInOnPrivy !== 'undefined' ? isSignedInOnPrivy : false,
      authAddress: currentPrivyWalletAddress ?? undefined,
      user: user ?? undefined,
      isSignedInOnNdao: undefined,
      isSignedInOnPrivy,
      isLoading: true,
    };
  // While we are waiting for Privy to be ready, we will pretend that the user is consistently signed in
  if (isPendingPrivy)
    return {
      isSignedIn: typeof isSignedInOnNdao !== 'undefined' ? isSignedInOnNdao : false,
      authAddress: currentNdaoWalletAddress,
      user: undefined,
      isSignedInOnPrivy,
      isSignedInOnNdao,
      isLoading: true,
    };
  const isLoading = isPendingPrivy || isPendingNdaoAuth;

  // We need to confirm that the user is signed in on both Privy and NDAO, and that the addresses match
  const isConsistentBetweenPrivyAndNdao = equalAddress(currentPrivyWalletAddress, currentNdaoWalletAddress);
  if (!isConsistentBetweenPrivyAndNdao || !isSignedInOnPrivy || !isSignedInOnNdao)
    return {
      isSignedIn: false,
      authAddress: undefined,
      user: undefined,
      isSignedInOnPrivy,
      isSignedInOnNdao,
      isLoading,
    };

  return {
    // We have already confirmed that Privy and NDAO are both signed in and the addresses match
    isSignedIn: true,
    authAddress: currentNdaoWalletAddress,
    user: user ?? undefined,
    isSignedInOnPrivy,
    isSignedInOnNdao,
    isLoading,
  };
};

/**
 * Get the wallet address to use for a user, based on their wallet type.
 *
 * For social users, this will return the smart account address.
 * For wallet users, this will return the EOA address.
 */
export const getPrivyWalletAddressForUser = async (user: User | null): Promise<Address | null> => {
  if (!user || !user.wallet) return null;

  const walletAddressParse = addressSchema.safeParse(user.wallet.address);
  if (!walletAddressParse.success) return null;
  const eoaAddress = walletAddressParse.data;

  // Wallet client type is 'privy' for social auth
  // Wallet users should use their EOA address
  if (!isUserWalletSocial(user.wallet)) return eoaAddress;

  // Social users should only use the smart account address, even if they have an embedded wallet
  return getSmartAccountAddressForEOA(eoaAddress);
};
