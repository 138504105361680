import { Fragment, useId } from 'react';

const dotXValues = [
  '244.5',
  '233.5',
  '222.5',
  '211.5',
  '200.5',
  '189.5',
  '178.5',
  '167.5',
  '156.5',
  '145.5',
  '134.5',
  '123.5',
  '112.5',
  '101.5',
  '90.5',
  '79.5',
  '68.5',
  '57.5',
  '46.5',
  '35.5',
  '24.5',
  '13.5',
  '2.5',
];
const dotYValues = [
  '194.5',
  '183.5',
  '172.5',
  '161.5',
  '150.5',
  '139.5',
  '128.5',
  '117.5',
  '106.5',
  '95.5',
  '84.5',
  '73.5',
  '62.5',
  '51.5',
  '40.5',
  '29.5',
  '18.5',
  '7.5',
];

export const CardDotsBackground = ({ className }: { className?: string }) => {
  const id = useId();
  const gradientId = useId();

  const circles = dotXValues.map((x, i) => (
    <Fragment key={i}>
      {dotYValues.map((y, j) => (
        <circle key={j} cx={x} cy={y} r='1.5' fill='#0083CF' />
      ))}
    </Fragment>
  ));

  return (
    <svg
      className={className}
      width='250'
      height='200'
      viewBox='0 0 250 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g style={{ mixBlendMode: 'multiply' }} clipPath={`url(#${id})`}>
        <rect width='250' height='200' transform='matrix(-1 0 0 1 250 0)' fill='white' />
        <rect width='250' height='200' transform='matrix(-1 0 0 -1 246 196)' fill='white' />
        {circles}
        <rect width='250' height='200' transform='matrix(-1 0 0 1 250 0)' fill={`url(#${gradientId})`} />
      </g>
      <defs>
        <radialGradient
          id={gradientId}
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(-9.0301e-06 200) rotate(-90) scale(200 250)'>
          <stop stopColor='white' stopOpacity='0.5' />
          <stop offset='0.317708' stopColor='white' stopOpacity='0.9' />
          <stop offset='0.807292' stopColor='white' />
        </radialGradient>
        <clipPath id={id}>
          <rect width='250' height='200' fill='white' transform='matrix(-1 0 0 1 250 0)' />
        </clipPath>
      </defs>
    </svg>
  );
};
