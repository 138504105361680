import type {
  FetchQueryOptions,
  InvalidateQueryFilters,
  QueryClient,
  QueryKey,
  SkipToken,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { skipToken, useMutation, useQuery } from '@tanstack/react-query';
import { ofetch } from 'ofetch';
import { ZodError } from 'zod';

import { config } from '@endaoment-frontend/config';
import { TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';

import { defaultQueryClient, queryClientForSSR } from './queryClient';

const baseURL = config.baseUrls.api;

const fetchClient = ofetch.create({
  baseURL,
  credentials: 'include',
});

/**
 * Constant for query key on requests that require authentication
 */
const USER_SPECIFIC_REQUEST_KEY = 'NeedAuth';

type RequestHandlerOptions<Args, MockPoints> = {
  isUserSpecificRequest?: boolean;
  augmentArgs?: (args: Args) => Array<unknown>;
  makeMockEndpoints?: (args: { baseURL: string }) => MockPoints;
};

type ExecuteFn<Args extends Array<unknown>, Return> = (...args: Args) => Promise<Return>;

const stringifyInArray = (arr: Array<unknown>): Array<unknown> =>
  arr.map(arg => {
    if (typeof arg === 'bigint') return arg.toString();
    if (typeof arg === 'object') return JSON.stringify(arg);
    return arg;
  });

export class RequestHandler<Args extends Array<unknown> = [], Return = void, MockPoints extends object = object> {
  /**
   * Determines if the query needs to be refetched when authentication changes
   * @default false
   */
  readonly isUserSpecificRequest: boolean = false;

  /**
   * Augment the arguments before passing them to the handler
   */
  private augmentArgs: (args: Args) => Array<unknown> = args => args;

  /**
   * Mock endpoint to use instead of the real one
   */
  readonly mockEndpoints: MockPoints;

  constructor(
    public key: string,
    private handlerFn: (request: typeof fetchClient) => ExecuteFn<Args, Return>,
    options?: RequestHandlerOptions<Args, MockPoints>,
  ) {
    if (options) {
      if (options.isUserSpecificRequest !== undefined) {
        this.isUserSpecificRequest = options.isUserSpecificRequest;
      }

      if (options.augmentArgs) {
        this.augmentArgs = options.augmentArgs;
      }

      if (options.makeMockEndpoints) {
        this.mockEndpoints = options.makeMockEndpoints({ baseURL });
      } else {
        this.mockEndpoints = {} as MockPoints;
      }
    }
  }

  /**
   * Wrapper for a request's handler
   */
  public get execute(): ExecuteFn<Args, Return> {
    return async (...args) => {
      // Need to await the promise so that it can be removed at the right time
      try {
        const res = await this.handlerFn(fetchClient)(...args);
        return res;
      } catch (err) {
        // Need to log validation errors for visibility
        if (err instanceof ZodError) {
          console.error(
            `Validation error on ${this.key}`,
            '\n',
            ...err.errors.map(e => `${e.path.join('.')}: ${e.message}\n`),
          );
        }

        throw err;
      }
    };
  }

  public async executeAndSave(args: Args): Promise<Return> {
    const res = await this.execute(...args);
    this.setData(defaultQueryClient, args, res);
    return res;
  }

  public get prefixKeys(): QueryKey {
    return this.isUserSpecificRequest ? [USER_SPECIFIC_REQUEST_KEY, this.key] : [this.key];
  }

  public getQueryKeyForArgs(args: Args): Array<unknown> {
    const augmentedArgs = this.augmentArgs(args);
    const argsWithStringifiedBigInts = stringifyInArray(augmentedArgs);
    return [...this.prefixKeys, ...argsWithStringifiedBigInts];
  }

  /**
   * Resets all queries that have the user specific request key
   */
  public static resetUserSpecificQueries() {
    defaultQueryClient.resetQueries({
      exact: false,
      type: 'all',
      predicate: query => {
        let shouldInvalidate = false;
        query.queryKey.forEach(qk => {
          if (qk === USER_SPECIFIC_REQUEST_KEY) shouldInvalidate = true;
        });
        return shouldInvalidate;
      },
    });
  }

  /**
   * Shortcut to useQuery without much customization
   * @param args Same as the arguments for invoking the function directly
   * @param options Same as the options you would normally pass to useQuery
   * @param extraKeys A space for query keys that are not necessary to invoke the request
   */
  public useQuery<Select = Return>(
    args: Args | SkipToken,
    options?: Omit<UseQueryOptions<Return, Error, Select, QueryKey>, 'queryFn' | 'queryKey'>,
    extraKeys: Array<unknown> = [],
  ) {
    const queryKey = [
      ...(args === skipToken ? this.prefixKeys : this.getQueryKeyForArgs(args)),
      ...stringifyInArray(extraKeys),
    ];
    return useQuery<Return, Error, Select>({
      queryKey,
      queryFn: args === skipToken ? skipToken : () => this.execute(...args),
      ...options,
    });
  }

  /**
   * Shortcut to useMutation without much customization
   * @param options Same as the options you would normally pass to useMutation
   */
  public useMutation(options?: Omit<UseMutationOptions<Return, Error, Args>, 'mutationFn' | 'mutationKey'>) {
    return useMutation<Return, Error, Args>({
      mutationKey: this.prefixKeys,
      mutationFn: args => this.execute(...args),
      ...options,
    });
  }

  /**
   * Shortcut to generate dehydrated query
   * @param args Same as the arguments for invoking the function directly
   * @param options Same as the options you would normally pass to useQuery, use this to override the default stale time or cache time
   * @param overrideQueryFn Use this to directly override the query function, useful if you already have the data
   * @dev We default to infinite stale time for SSG queries since most pages will use ISR and redo the query based on the revalidation time
   */
  public getDehydratedQueryArgs<Select = Return>(
    args: Args,
    options?: Omit<FetchQueryOptions<Return, Error, Select, QueryKey>, 'queryFn' | 'queryKey' | 'retry'>,
    overrideQueryFn?: () => Promise<Return> | Return,
  ): FetchQueryOptions {
    return {
      queryKey: this.getQueryKeyForArgs(args),
      queryFn: () => (overrideQueryFn ? overrideQueryFn() : this.execute(...args)),
      // This will allow the client to refetch the data if it's older than 1 minute
      staleTime: TIME_ONE_MINUTE_IN_SECONDS * 1000,
      retry: false,
      ...options,
    } satisfies FetchQueryOptions<Return, Error, Select, QueryKey> as FetchQueryOptions;
  }

  /**
   * Shortcut to invalidate a query
   * @param queryClient The query client to use
   * @param args Same as the arguments for invoking the function directly
   * @param filters Filters to apply to the invalidate function
   */
  public invalidateQuery(queryClient: QueryClient, args?: Args, filters?: InvalidateQueryFilters) {
    if (!args) {
      queryClient.invalidateQueries({ queryKey: this.prefixKeys, ...filters, exact: false });
      return;
    }

    queryClient.invalidateQueries({ queryKey: this.getQueryKeyForArgs(args), exact: true, ...filters });
  }

  public invalidateDefaultClientQuery(args?: Args, filters?: InvalidateQueryFilters) {
    this.invalidateQuery(defaultQueryClient, args, filters);
  }

  /**
   * Shortcut to set the data for a query
   * @param queryClient The query client to use
   * @param args Same as the arguments for invoking the function directly
   */
  public setData(queryClient: QueryClient, args: Args, data: Return | ((oldData?: Return) => Return | undefined)) {
    return queryClient.setQueryData(this.getQueryKeyForArgs(args), data);
  }

  /**
   * Shortcut to fetch a query imperatively, use sparingly
   */
  public fetchFromQueryClient(
    queryClient: QueryClient,
    args: Args,
    options?: Omit<UseQueryOptions<Return, Error, Return, QueryKey>, 'queryFn' | 'queryKey'>,
  ): Promise<Return> {
    return queryClient.fetchQuery({
      queryKey: this.getQueryKeyForArgs(args),
      queryFn: () => this.execute(...args),
      ...options,
    });
  }

  /**
   * Shortcut to fetch a query during SSR, use only if you need to actually use the data during SSR
   */
  public fetchForSSR(args: Args): Promise<Return> {
    return this.fetchFromQueryClient(queryClientForSSR, args);
  }

  public fetchFromDefaultClient(args: Args): Promise<Return> {
    return this.fetchFromQueryClient(defaultQueryClient, args);
  }
}
