import { Button, Container, Heading } from '@chakra-ui/react';
import type { ReactNode } from 'react';

import { isAdministrativeAccount, useAuth } from '@endaoment-frontend/authentication';
import { useIsMounted } from '@endaoment-frontend/hooks';

import { useAdminLogin } from '../../utils/useAdminLogin';

const LoginDisplay = () => {
  const { login } = useAdminLogin();
  const isMounted = useIsMounted();

  return (
    <Container centerContent marginY='20vh'>
      <Heading>Admin Login</Heading>
      {!!isMounted && <Button onClick={() => login()}>Sign in</Button>}
    </Container>
  );
};

const AdminDisplayBarrier = ({ children }: { children: Array<ReactNode> | ReactNode }) => {
  const { isSignedIn, authAddress } = useAuth();

  const isMounted = useIsMounted();

  if (!isSignedIn || !isMounted || !isAdministrativeAccount(authAddress)) return <LoginDisplay />;

  return <>{children}</>;
};

export const AdminLoginWrapper = ({ children }: { children: Array<ReactNode> | ReactNode }) => {
  return <AdminDisplayBarrier>{children}</AdminDisplayBarrier>;
};
