import clsx from 'clsx';
import type { ReactElement } from 'react';

import { ArbitrumChainIcon } from './ArbitrumChainIcon';
import { BaseChainIcon } from './BaseChainIcon';
import styles from './ChainIcon.module.scss';
import { EthereumChainIcon } from './EthereumChainIcon';
import { OptimismChainIcon } from './OptimismChainIcon';
import { PolygonChainIcon } from './PolygonChainIcon';

const chainIconMap: { [key: number]: ReactElement } = {
  1: <EthereumChainIcon color='currentColor' />,
  10: <OptimismChainIcon color='currentColor' />,
  420: <OptimismChainIcon color='currentColor' />, // Optimism Goerli
  137: <PolygonChainIcon color='currentColor' />,
  80001: <PolygonChainIcon color='currentColor' />, // Polygon Mumbai
  8453: <BaseChainIcon color='currentColor' />,
  84531: <BaseChainIcon color='currentColor' />, // Base Goerli
  84532: <BaseChainIcon color='currentColor' />, // Base Sepolia
  31338: <BaseChainIcon color='currentColor' />, // Foundry 2
  42161: <ArbitrumChainIcon color='currentColor' />,
  421613: <ArbitrumChainIcon color='currentColor' />, // Arbitrum Goerli
  11155111: <EthereumChainIcon color='currentColor' />, // Sepolia
} as const;

export const ChainIcon = ({
  chainId,
  filled,
  className,
  light,
  name,
  tiny,
}: {
  chainId: number;
  filled?: boolean;
  className?: string;
  light?: boolean;
  name?: string;
  tiny?: boolean;
}) => {
  const Icon = () => chainIconMap[chainId] ?? chainIconMap[1];
  return filled ? (
    <span
      className={clsx(
        className,
        styles['network-icon'],
        styles[`network-icon--${chainId}`],
        light && styles['network-icon__light'],
        light && styles[`network-icon__light--${chainId}`],
        name && styles['network-icon--name'],
        tiny && styles['network-icon--tiny'],
      )}>
      <Icon />
      {name}
    </span>
  ) : (
    <Icon />
  );
};
