import type { As } from '@chakra-ui/react';
import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import type { PolymorphicProps, PolymorphicRef } from '@endaoment-frontend/types';

import styles from './Tooltip.module.scss';

type Placement = 'bottom' | 'left' | 'right' | 'top';

export type TooltipProps<Tag extends As> = PolymorphicProps<
  'span',
  Tag,
  {
    content: ReactNode;
    allowRotate?: boolean;
    placement?: Placement;
    children?: ReactNode;
    className?: string;
    innerClassName?: string;
  }
>;

const TooltipWithRef = <Tag extends As>(
  {
    content,
    placement = 'top',
    children,
    as,
    className,
    innerClassName,
    allowRotate = false,
    ...props
  }: TooltipProps<Tag>,
  ref: PolymorphicRef<Tag>,
) => {
  return (
    <ChakraTooltip
      ref={ref}
      as={as}
      label={<div className={clsx(innerClassName, styles['chakra-tooltip'])}>{content}</div>}
      placement={placement}
      display='inline-block'
      shouldWrapChildren={false}
      isDisabled={!content}
      closeOnClick={false}
      {...props}>
      <span className={clsx(className, styles['chakra-tooltip__content'])}>{children}</span>
    </ChakraTooltip>
  );
};
export const Tooltip = forwardRef(TooltipWithRef);
