import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

export const WalletPermissionsModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Container centerContent>
            <Heading size='md'>Wallet Permissions</Heading>
            <FormControl>
              <FormLabel>Trust Wallet</FormLabel>
              <Input />
            </FormControl>
            <FormControl>
              <FormLabel>Board Wallet</FormLabel>
              <Input />
            </FormControl>
            <FormControl>
              <FormLabel>Portfolio Wallet</FormLabel>
              <Input />
            </FormControl>
            <FormControl>
              <FormLabel>Finance Wallet</FormLabel>
              <Input />
            </FormControl>
            <Button>Save Changes</Button>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
