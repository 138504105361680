import { Link } from '@chakra-ui/next-js';
import { Box, Button, Heading } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { getEndaomentAccountName, useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import type { AdminAccountName } from '@endaoment-frontend/types';

import { OrgDeployerModal } from '../orgDeployerModal';
import { WalletPermissionsModal } from '../walletPermissionsModal';

const pathMap: Array<{
  label: string;
  href: string;
  /** @deprecated */
  allowedRoles: Array<Capitalize<AdminAccountName>>;
}> = [
  { label: 'Funds', href: '/funds', allowedRoles: ['Accountant', 'Reviewer'] },
  { label: 'Grants', href: '/grants', allowedRoles: ['Accountant', 'Reviewer'] },
  { label: 'Donations', href: '/donations', allowedRoles: ['Accountant', 'Reviewer'] },
  { label: 'Payouts', href: '/payouts', allowedRoles: ['Accountant'] },
  { label: 'Claims', href: '/claims', allowedRoles: ['Reviewer'] },
  { label: 'Portfolios', href: '/portfolios', allowedRoles: ['Maintainer'] },
];

export const Navbar = () => {
  // Get which href is currently active
  const { asPath } = useRouter();

  // Auth status
  const { authAddress } = useAuth();
  const { showWallet } = useWalletModal();
  const accountName = getEndaomentAccountName(authAddress);

  // Modals
  const [isOrgDeployerOpen, setIsOrgDeployerOpen] = useState(false);
  const [isWalletPermissionsOpen, setIsWalletPermissionsOpen] = useState(false);

  return (
    <>
      <Box as='header' marginBottom='4'>
        <Box padding='4' borderBottom='1px solid' display='flex' alignItems='center' gap='4'>
          <Heading size='md'>Endaoment Admin Panel</Heading>
          {/* <Button marginLeft="auto" onClick={() => setIsOrgDeployerOpen(true)}>
            Org Deployer
          </Button>
          <Button onClick={() => setIsWalletPermissionsOpen(true)}>
            Wallet Permissions
          </Button> */}
          <Button marginLeft='auto' onClick={() => showWallet(true)} variant='unstyled'>
            {accountName} Wallet
          </Button>
        </Box>
        <Box padding='4' display='flex' alignItems='center' gap='4'>
          {pathMap.map(({ href, label }) => (
            <Link
              key={href}
              href={href}
              textUnderlineOffset='8px'
              textDecorationColor={href === asPath ? 'black' : 'lightgray'}
              textDecorationLine='underline'>
              {label}
            </Link>
          ))}
        </Box>
      </Box>

      <OrgDeployerModal isOpen={isOrgDeployerOpen} onClose={() => setIsOrgDeployerOpen(false)} />
      <WalletPermissionsModal isOpen={isWalletPermissionsOpen} onClose={() => setIsWalletPermissionsOpen(false)} />
    </>
  );
};
