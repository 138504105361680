import { baseSepolia, optimismSepolia, sepolia } from 'viem/chains';

import type { ConfigEntry } from './config.types';
import { DOT_ORG_TEAM, NDAO_WALLETS } from './helpers';

export const config: ConfigEntry = {
  environmentName: 'dev',
  chains: [sepolia, optimismSepolia, baseSepolia],
  baseUrls: {
    app: 'https://app.dev.endaoment.org',
    marketing: 'https://dev.endaoment.org',
    api: 'https://api.dev.endaoment.org',
    admin: 'https://admin.dev.endaoment.org',
    developers: 'https://developers.dev.endaoment.org',
    docs: 'https://docs.endaoment.org',
  },
  endaoment: {
    accounts: {
      accountant: ['0x9928Fa2b51f7e2ccE76Cf0a6aED1b7e4afE0CC0f'],
      maintainer: [
        NDAO_WALLETS.PEDRO,
        NDAO_WALLETS.PIETRO,
        NDAO_WALLETS.ZACH,
        NDAO_WALLETS.ROBBIE,
        NDAO_WALLETS.RICARDO,
        NDAO_WALLETS.NOAH,
        NDAO_WALLETS.ISHAAN,
      ],
      reviewer: ['0x78062b69CffD97e6E0bBA4Cd71B0EC436B634E10'],
      keeper: [...DOT_ORG_TEAM, NDAO_WALLETS.TEST_PAUSER_WALLET],
    },
  },
  socialLoginChainId: optimismSepolia.id,
  zeroDevProjectId: 'bcba09cd-0884-43c3-98f4-128ebf84572f',
  privyAppId: 'clv19o5jv06nxtjte9ecj88o0',
  circleAddress: '0xd31361f706970b0071df3ebb24fec35a4870e096',
  googleMapsApiKey: 'AIzaSyBNX_ixICIxKEUHNiWFGdBBbi42OD4GN7k',
  mapboxApiKey: 'pk.eyJ1IjoiZW5kYW9tZW50IiwiYSI6ImNsaGo5MDRmdzBmZXozZnBiMHV1NWRjcGIifQ.HfvViRsi4sKeYxFYt7-BQw',
  identifiersSecret: '0c8b1114-b048-4f6b-b33e-7ed296442555f5e1d732-6a0c-468e-9c3e-e8ce37e4e56e',
};
